import pkg from '../../package.json';


export const environment = {
  version: pkg.version,
  hmr: false,
  production: true,
  baseUrl: "https://sb8.azurewebsites.net/api/v1/",
  signalRUrl: 'https://sb8.azurewebsites.net/hub/notification',
  googleMapskey: "AIzaSyDaXyX_LvkVMSM0xId6uJIwqfu-Euw-gYA",
};
